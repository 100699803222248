<meic-dialog>
	@if (userData$ | async; as user) {
		<meiw-user-form
			[form]="form"
			[user]="user"
			(submitted)="onSubmit()"
			(cancelled)="closeDialogWithResult(false)"
			(editPermissions)="editPermissions()"
		></meiw-user-form>
	}
</meic-dialog>
