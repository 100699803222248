import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { DataWithWrapperDto } from '../dtos/data-with-wrapper.dto';
import { permissionDtoSchema, permissionResultDtoSchema } from '../dtos/permission.dto';
import { userRoleDtoSchema } from '../dtos/user-role.dto';
import { PermissionMapper } from '../mappers/permission.mapper';
import { UserRoleMapper } from '../mappers/user-role.mapper';
import { Permission } from '../models/permission';
import { QuoteDetail } from '../models/quote/quote-detail';
import { User } from '../models/user';
import { UserRole } from '../models/user-role';
import { secureParse } from '../utils/secureParse';

import { Region } from '../models/region';

import { regionDtoSchema } from '../dtos/region.dto';
import { RegionMapper } from '../mappers/region.mapper';

import { AppErrorMapper } from '../mappers/app-error.mapper';

import { AppUrlsConfig } from './app-urls.config';

/** Permission Service. */
@Injectable({
	providedIn: 'root',
})
export class PermissionApiService {
	private readonly httpClient = inject(HttpClient);

	private readonly appUrlsConfig = inject(AppUrlsConfig);

	private readonly permissionMapper = inject(PermissionMapper);

	private readonly userRoleMapper = inject(UserRoleMapper);

	private readonly regionMapper = inject(RegionMapper);

	private readonly errorMapper = inject(AppErrorMapper);

	/**
	 * Can user create quote.
	 * @param userId User ID.
	 */
	public canCreateQuote(userId: User['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canCreateQuotes(userId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}

	/**
	 * Can user edit quote.
	 * @param userId User ID.
	 * @param quoteId Quote ID.
	 */
	public canEditQuote(userId: User['id'], quoteId: QuoteDetail['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canEditQuote(userId, quoteId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}

	/**
	 * Can user view quote.
	 * @param userId User ID.
	 * @param quoteId Quote ID.
	 */
	public canViewQuote(userId: User['id'], quoteId: QuoteDetail['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canViewQuote(userId, quoteId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}

	/**
	 * Can user edit permissions.
	 * @param userId User ID.
	 */
	public canEditPermissions(userId: User['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canEditPermissions(userId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}

	/**
	 * Can user approve quote.
	 * @param userId User ID.
	 * @param quoteId Quote ID.
	 */
	public canApproveQuote(userId: User['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canApproveQuote(userId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}

	/**
	 * Can edit users.
	 * @param userId User ID.
	 */
	public canEditUsers(userId: User['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canEditUsers(userId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}

	/** Get all available permissions. */
	public getList(): Observable<Permission[]> {
		return this.httpClient.get<DataWithWrapperDto>(
			this.appUrlsConfig.permission.list,
		).pipe(
			map(response => secureParse(response.data, permissionDtoSchema.array())),
			map(permissionDtos => permissionDtos.map(dto => this.permissionMapper.fromDto(dto))),
		);
	}

	/** Get all available roles. */
	public getRoles(): Observable<UserRole[]> {
		return this.httpClient.get<DataWithWrapperDto>(
			this.appUrlsConfig.permission.roles,
		).pipe(
			map(response => secureParse(response.data, userRoleDtoSchema.array())),
			map(roleDtos => roleDtos.map(dto => this.userRoleMapper.fromDto(dto))),
		);
	}

	/**
	 * Get permissions of specific user.
	 * @param userId User ID.
	 */
	public getUserPermissions(userId: User['id']): Observable<Permission[]> {
		return this.httpClient.get<DataWithWrapperDto>(
			this.appUrlsConfig.permission.userPermissions(userId),
		).pipe(
			map(response => secureParse(response.data, permissionDtoSchema.array())),
			map(permissionDtos => permissionDtos.map(dto => this.permissionMapper.fromDto(dto))),
		);
	}

	/**
	 * Get all regions base on user.
	 * @param userId User ID.
	 */
	public getRegions(userId: User['id']): Observable<Region[]> {
		return this.httpClient.get<DataWithWrapperDto>(this.appUrlsConfig.permission.regions(userId)).pipe(
			map(response => secureParse(response.data, regionDtoSchema.array())),
			map(dtos => dtos.map(dto => this.regionMapper.fromDto(dto))),
		);
	}

	/**
	 * Save permissions.
	 * @param userId User ID.
	 * @param permissions Permissions.
	 */
	public save(userId: User['id'], permissions: string[]): Observable<void> {
		return this.httpClient.put(this.appUrlsConfig.permission.edit(userId), { permissions }).pipe(
			map(() => undefined),
			this.errorMapper.catchHttpErrorToAppError(),
		);
	}

	/**
	 * Can delete equipment.
	 * @param userId User id.
	 */
	public canDeleteEquipment(userId: User['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canDeleteEquipment(userId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}

	/**
	 * Can delete rental equipment.
	 * @param userId User id.
	 */
	public canDeleteRentalEquipment(userId: User['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canDeleteRentalEquipment(userId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}

	/**
	 * Can access equipment manager.
	 * @param userId User id.
	 */
	public canAccessEquipmentManager(userId: User['id']): Observable<boolean> {
		return this.httpClient
			.get(this.appUrlsConfig.permission.canAccessEquipmentManager(userId))
			.pipe(
				map(response => secureParse(response, permissionResultDtoSchema)),
				map(permissionDto => this.permissionMapper.fromResultDto(permissionDto)),
			);
	}
}
