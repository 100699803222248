import { z } from 'zod';

/** Permission schema. */
export const permissionSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string(),
});

/** Permission. */
export type Permission = Readonly<z.infer<typeof permissionSchema>>;
