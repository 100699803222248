import { inject, Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { User, UserCreation, UserEdit } from '@mei/common/core/models/user';
import { FlatControlsOf } from '@mei/common/core/utils/types/controls-of';
import { StrictOmit } from '@mei/common/core/utils/types/strict-omit';

/** User edit form data. */
export type UserEditFormData = FlatControlsOf<
StrictOmit<UserEdit, 'roleId' | 'locationId'> & {

	/** Role ID. */
	readonly roleId: number | null;

	/** Location ID. */
	readonly locationId: number | null;

	/** User company. */
	readonly company: string;

	/** Url for displaying the picture. */
	readonly displayPictureUrl: string;
}
>;

/** User creation form data. */
export type UserCreationFormData = FlatControlsOf<
StrictOmit<UserCreation, 'roleId' | 'locationId'> & {

	/** Role ID. */
	readonly roleId: number | null;

	/** Location ID. */
	readonly locationId: number | null;

	/** User company. */
	readonly company: string;

	/** Url for displaying the picture. */
	readonly displayPictureUrl: string;
}
>;

/** User form service. */
@Injectable({
	providedIn: 'root',
})
export class UserFormService {
	private readonly fb = inject(NonNullableFormBuilder);

	/** Initialize edit form. */
	public initializeEditForm(): FormGroup<UserEditFormData> {
		return this.fb.group<UserEditFormData>({
			fullName: this.fb.control('', Validators.required),
			email: this.fb.control('', Validators.required),
			locationId: this.fb.control(null),
			roleId: this.fb.control(null, Validators.required),
			profilePicture: this.fb.control(null),
			company: this.fb.control({ value: '', disabled: true }),
			displayPictureUrl: this.fb.control(''),
		});
	}

	/** Initialize creation form. */
	public initializeCreationForm(): FormGroup<UserCreationFormData> {
		return this.fb.group<UserCreationFormData>({
			fullName: this.fb.control('', Validators.required),
			email: this.fb.control('', Validators.required),
			roleId: this.fb.control(null, Validators.required),
			company: this.fb.control({ value: '', disabled: true }),
			displayPictureUrl: this.fb.control(''),
			locationId: this.fb.control(null),
			userId: this.fb.control('', Validators.required),
			permissions: this.fb.control([]),
			profilePicture: this.fb.control(null),
		});
	}

	/**
	 * Fill form data.
	 * @param form Form data.
	 * @param user User data.
	 */
	public fillEditFormData(form: FormGroup<UserEditFormData>, user: User): void {
		form.patchValue({
			fullName: user.fullName,
			email: user.email,
			locationId: user.location?.id,
			roleId: user.role?.id,
			company: user.company?.name ?? '',
		});
		form.controls.displayPictureUrl.setValue(this.getPictureUrl(user.profilePicture));
	}

	/**
	 * Because the profile URL picture of a user is not changed after they edit it.
	 * But the browser caches the profile picture each time it gets changed.
	 * So we will add a unique string after the URL to prevent it.
	 * Ref: https://stackoverflow.com/questions/126772/how-to-force-a-web-browser-not-to-cache-images.
	 * @param url Picture url.
	 */
	protected getPictureUrl(url: string): string {
		return `${url}?${new Date().getTime()
			.toString()}`;
	}
}
