import { inject, Injectable } from '@angular/core';

import { UserRoleDto, UserRoleTypeDto } from '../dtos/user-role.dto';
import { UserRole, UserRoleType } from '../models/user-role';

import { MapperFromDto } from './mappers';
import { PermissionMapper } from './permission.mapper';

const USER_ROLE_TYPE_MAP_FROM_DTO: Readonly<Record<UserRoleTypeDto, UserRoleType>> = {
	[UserRoleTypeDto.Company]: UserRoleType.Company,
	[UserRoleTypeDto.Location]: UserRoleType.Location,
	[UserRoleTypeDto.Default]: UserRoleType.Default,
	[UserRoleTypeDto.Region]: UserRoleType.Region,
};

/** User role mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserRoleMapper implements MapperFromDto<UserRoleDto, UserRole> {
	private readonly permissionMapper = inject(PermissionMapper);

	/** @inheritdoc */
	public fromDto(dto: UserRoleDto): UserRole {
		return {
			id: dto.id,
			name: dto.name,
			description: dto.description,
			type: USER_ROLE_TYPE_MAP_FROM_DTO[dto.type],
			defaultPermissions: dto.permissions ?
				dto.permissions.map(permissionDto => this.permissionMapper.fromDto(permissionDto)) :
				undefined,
		};
	}
}
