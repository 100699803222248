import { Injectable, inject } from '@angular/core';

import { UserConfigurationDto, UserCreationDto, UserDto, UserEditDto } from '../dtos/user.dto';
import { User, UserConfiguration, UserCreation, UserEdit } from '../models/user';

import { ValidationErrorDto } from '../dtos/validation-error.dto';

import { EntityValidationErrors } from '../models/app-error';

import { LocationMapper } from './location.mapper';
import { MapperToCreationDto, MapperToEditDto, ValidationErrorMapper } from './mappers';
import { UserRoleMapper } from './user-role.mapper';
import { CompanyMapper } from './company.mapper';

import { extractErrorMessageByField } from './extract-error-message';

/** User mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserMapper implements
MapperToEditDto<UserEditDto, UserEdit>,
MapperToCreationDto<UserCreationDto, UserCreation>,
ValidationErrorMapper<UserCreationDto, UserCreation> {
	private readonly locationMapper = inject(LocationMapper);

	private readonly userRoleMapper = inject(UserRoleMapper);

	private readonly companyMapper = inject(CompanyMapper);

	/**
	 * Maps user from dto and append the profile picture url if exists.
	 * @param dto Dto data.
	 * @param profilePicture Profile picture url.
	 */
	public fromDto(dto: UserDto, profilePicture = ''): User {
		return {
			id: dto.id,
			email: dto.email,
			fullName: dto.fullname,
			location: dto.location ? this.locationMapper.fromDto(dto.location) : null,
			defaultLogo: dto.default_logo,
			defaultTermId: dto.default_terms_id,
			includeCustomerEquipment: Boolean(dto.customer_equipment_pdf),
			role: dto.role ? this.userRoleMapper.fromDto(dto.role) : null,
			profilePicture,
			company: dto.company ? this.companyMapper.fromDto(dto.company) : null,
			isMasquerading: dto.is_masquerading,
		};
	}

	/**
	 * Maps to configuration dto.
	 * @param data Configuration data.
	 */
	public toConfigurationDto(data: UserConfiguration): UserConfigurationDto {
		return {
			default_logo: data.defaultLogo,
			default_terms_id: data.defaultTermId,
			customer_equipment_pdf: data.includeCustomerEquipment ? 1 : 0,
		};
	}

	/** @inheritdoc */
	public toEditDto(data: UserEdit): UserEditDto {
		return {
			fullname: data.fullName,
			email: data.email,
			location_id: data.locationId,
			role_id: data.roleId,
			file: data.profilePicture,
		};
	}

	/** @inheritdoc */
	public toCreationDto(data: UserCreation): UserCreationDto {
		return {
			fullname: data.fullName,
			email: data.email,
			user_id: data.userId,
			role_id: data.roleId,
			location_id: data.locationId,
			file: data.profilePicture,
			permissions: data.permissions,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<UserCreationDto>): EntityValidationErrors<UserCreation> {
		return {
			email: extractErrorMessageByField('email', errorDto),
			fullName: extractErrorMessageByField('fullname', errorDto),
			userId: extractErrorMessageByField('user_id', errorDto),
			roleId: extractErrorMessageByField('role_id', errorDto),
			locationId: extractErrorMessageByField('location_id', errorDto),
		};
	}
}
