import { z } from 'zod';

import { locationDtoSchema } from './location.dto';
import { userRoleDtoSchema } from './user-role.dto';
import { companyDtoSchema } from './company.dto';
import { permissionDtoSchema } from './permission.dto';

/** User DTO schema. */
export const userDtoSchema = z.object({
	id: z.number(),
	email: z.string(),
	fullname: z.string(),
	location: locationDtoSchema.nullable(),
	default_terms_id: z.number().nullable(),
	default_logo: z.string().nullable(),
	customer_equipment_pdf: z.number(),
	role: userRoleDtoSchema.nullable(),
	company: companyDtoSchema.nullable(),
	is_masquerading: z.boolean(),
});

/** User edit dto schema. */
export const userEditDtoSchema = userDtoSchema.pick({
	fullname: true,
	email: true,
}).extend({
	location_id: z.number(),
	role_id: z.number(),
	file: z.instanceof(File).nullable(),
});

/** User creation dto schema. */
export const userCreationDtoSchema = userDtoSchema.pick({
	fullname: true,
	email: true,
}).extend({
	user_id: z.string(),
	role_id: z.number(),
	location_id: z.number(),
	file: z.instanceof(File).nullable(),
	permissions: permissionDtoSchema.shape.name.array(),
});

/** User configuration dto schema. */
export const userConfigurationDtoSchema = userDtoSchema.pick({
	default_logo: true,
	default_terms_id: true,
	customer_equipment_pdf: true,
});

/** User DTO. */
export type UserDto = Readonly<z.infer<typeof userDtoSchema>>;

/** User configuration DTO. */
export type UserConfigurationDto = Readonly<z.infer<typeof userConfigurationDtoSchema>>;

/** User edit dto. */
export type UserEditDto = Readonly<z.infer<typeof userEditDtoSchema>>;

/** User creation dto. */
export type UserCreationDto = Readonly<z.infer<typeof userCreationDtoSchema>>;
