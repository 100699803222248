import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AppError } from '@mei/common/core/models/app-error';
import { PasswordChange, passwordChangeSchema } from '@mei/common/core/models/password-change';
import { NotificationService } from '@mei/common/core/services/notification.service';
import { UserService } from '@mei/common/core/services/user.service';
import { catchValidationData } from '@mei/common/core/utils/rxjs/catch-validation-error';
import { toggleExecutionState } from '@mei/common/core/utils/rxjs/toggle-execution-state';
import { FlatControlsOf } from '@mei/common/core/utils/types/controls-of';
import { AppValidators } from '@mei/common/core/utils/validators';
import { LabelComponent } from '@mei/common/shared/components/label/label.component';
import { PasswordFieldComponent } from '@mei/common/shared/components/password-field/password-field.component';
import { LoadingDirective } from '@mei/common/shared/directives/loading.directive';
import { BehaviorSubject, Observable, catchError, map, merge, tap } from 'rxjs';

import { webRoutePaths } from '../shared/web-route-paths';

type ChangePasswordFormData = FlatControlsOf<PasswordChange>;

/** Change password page. */
@Component({
	selector: 'meiw-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['../auth/auth.css', './change-password.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ReactiveFormsModule, LabelComponent, RouterLink, LoadingDirective, AsyncPipe, PasswordFieldComponent],
})
export class ChangePasswordComponent implements OnInit {
	/** Is app loading. */
	protected readonly isLoading$ = new BehaviorSubject<boolean>(false);

	/** Change password form. */
	protected readonly changePasswordForm: FormGroup<ChangePasswordFormData>;

	private readonly fb = inject(NonNullableFormBuilder);

	private readonly userService = inject(UserService);

	private readonly destroyRef = inject(DestroyRef);

	private readonly router = inject(Router);

	private readonly route = inject(ActivatedRoute);

	private readonly notificationService = inject(NotificationService);

	/** Change password token. */
	public readonly token$ = this.route.queryParams.pipe(map(queryParams => queryParams['token'] ?? null));

	public constructor() {
		this.changePasswordForm = this.initChangePasswordForm();
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		merge(this.assignTokenSideEffect())
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}

	/** Handle submit of the change password form. */
	protected onSubmit(): void {
		this.changePasswordForm.markAllAsTouched();
		if (this.changePasswordForm.invalid) {
			return;
		}
		const changePasswordData = passwordChangeSchema.parse(this.changePasswordForm.value);
		this.userService
			.changePassword(changePasswordData)
			.pipe(
				toggleExecutionState(this.isLoading$),
				catchValidationData(this.changePasswordForm),
				catchError((error: unknown) => {
					if (error instanceof AppError) {
						this.notificationService.notify(error.message);
					}
					throw error;
				}),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe({
				complete: () => {
					this.notificationService.notify('Password changed successfully!');
					this.router.navigate([webRoutePaths.home.path]);
				},
			});
	}

	private initChangePasswordForm(): FormGroup<ChangePasswordFormData> {
		return this.fb.group<ChangePasswordFormData>({
			newPassword: this.fb.control('', [Validators.required]),
			newPasswordConfirmation: this.fb.control('', [Validators.required, AppValidators.matchControl('newPassword', 'New Password')]),
			token: this.fb.control('', Validators.required),
		});
	}

	private assignTokenSideEffect(): Observable<void> {
		return this.token$.pipe(
			tap(token => token != null && this.changePasswordForm.controls.token.setValue(token)),
		);
	}
}
