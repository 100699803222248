import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../models/app-error';
import { ForgotPassword, PasswordChange } from '../models/password-change';

import { ForgotPasswordDto, PasswordChangeDto } from '../dtos/password-change.dto';
import { ValidationErrorDto } from '../dtos/validation-error.dto';

import { extractErrorMessageByField } from './extract-error-message';
import { MapperToDto, ValidationErrorMapper } from './mappers';

/**
 * Mapper for change password process.
 */
@Injectable({ providedIn: 'root' })
export class PasswordChangeMapper
implements
    MapperToDto<PasswordChangeDto, PasswordChange>,
    ValidationErrorMapper<PasswordChangeDto, PasswordChange> {
	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<PasswordChangeDto>,
	): EntityValidationErrors<PasswordChange> {
		return {
			newPassword: extractErrorMessageByField('password', errorDto),
			newPasswordConfirmation: extractErrorMessageByField('new_password_confirm', errorDto),
			token: extractErrorMessageByField('token', errorDto),
		};
	}

	/** @inheritdoc */
	public toDto(model: PasswordChange): PasswordChangeDto {
		return {
			password: model.newPassword,
			password_confirmation: model.newPasswordConfirmation,
			token: model.token,
		};
	}

	/**
	 * Map forgot password dto to model.
	 * @param dto Dto.
	 */
	public fromForgotPasswordDto(dto: ForgotPasswordDto): ForgotPassword {
		return {
			token: dto.token,
		};
	}
}
