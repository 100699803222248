import { inject } from '@angular/core';

import { Router, UrlTree } from '@angular/router';
import { injectWebAppRoutes } from 'projects/web/src/app/features/shared/web-route-paths';
import { Observable, map } from 'rxjs';

import { PermissionService } from '../services/permission.service';

/** Equipment manager guard. */
export function equipmentManagerGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();

	const router = inject(Router);

	const permissionService = inject(PermissionService);

	return permissionService.canAccessEquipmentManager$.pipe(
		map(canAccess => (canAccess ? true : router.parseUrl(routePaths.home.path))),
	);
}
