import { z } from 'zod';

import { companySchema } from './company';
import { locationSchema } from './location';
import { permissionSchema } from './permission';
import { regionSchema } from './region';
import { userRoleSchema } from './user-role';

/** User schema. */
export const userSchema = z
	.object({
		id: z.number(),
		email: z.string(),
		fullName: z.string(),
		location: locationSchema.nullable(),
		defaultTermId: z.number().nullable(),
		defaultLogo: z.string().nullable(),
		includeCustomerEquipment: z.boolean(),
		role: userRoleSchema.nullable(),
		profilePicture: z.string(),
		company: companySchema.nullable(),
		isMasquerading: z.boolean(),
	});

/** User configuration schema. */
export const userConfigurationSchema = userSchema.pick({
	defaultLogo: true,
	defaultTermId: true,
	includeCustomerEquipment: true,
});

/** User edit schema. */
export const userEditSchema = userSchema.pick({
	fullName: true,
	email: true,
}).extend({
	roleId: userRoleSchema.shape.id,
	locationId: regionSchema.shape.id,
	profilePicture: z.instanceof(File).nullable(),
});

/** User creation schema. */
export const userCreationSchema = userSchema.pick({
	fullName: true,
	email: true,
}).extend({
	userId: z.string(),
	locationId: regionSchema.shape.id,
	roleId: userRoleSchema.shape.id,
	profilePicture: z.instanceof(File).nullable(),
	permissions: permissionSchema.shape.name.array(),
});

/** Basic representation of a user. */
export type User = Readonly<z.infer<typeof userSchema>>;

/** User configuration. */
export type UserConfiguration = Readonly<z.infer<typeof userConfigurationSchema>>;

/** User edit schema. */
export type UserEdit = Readonly<z.infer<typeof userEditSchema>>;

/** User creation schema. */
export type UserCreation = Readonly<z.infer<typeof userCreationSchema>>;
