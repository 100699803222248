import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { User, userEditSchema } from '@mei/common/core/models/user';
import { DialogData, DialogService } from '@mei/common/core/services/dialog.service';
import { PermissionService } from '@mei/common/core/services/permission.service';
import { UserApiService } from '@mei/common/core/services/user-api.service';
import { UserService } from '@mei/common/core/services/user.service';
import { filterNull } from '@mei/common/core/utils/rxjs/filter-null';
import { toggleExecutionState } from '@mei/common/core/utils/rxjs/toggle-execution-state';
import { secureParse } from '@mei/common/core/utils/secureParse';
import { DialogComponent } from '@mei/common/shared/components/dialog/dialog.component';
import { BehaviorSubject, filter, first, ignoreElements, merge, Observable, shareReplay, switchMap, tap } from 'rxjs';

import { UserFormComponent } from '../user-form/user-form.component';
import { UserFormService } from '../user-form/user-form.service';
import { PermissionsDialogComponent, PermissionDialogData } from '../../profile/permissions-dialog/permissions-dialog.component';
import { NavigationManagementService } from '../../../layouts/components/navigation/navigation.service';

/** User edit dialog data. */
export type UserEditDialogData = DialogData & {

	/** User ID. If use ID is null, that means the profile dialog is of the current user.*/
	readonly userId?: User['id'];
};

/** User edit dialog. */
@Component({
	selector: 'meiw-user-edit-dialog',
	templateUrl: './user-edit-dialog.component.html',
	styleUrl: './user-edit-dialog.component.css',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		DialogComponent,
		MatSelectModule,
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		UserFormComponent,
		AsyncPipe,
	],
})
export class UserEditDialogComponent extends DialogComponent<UserEditDialogData> implements OnInit {

	private readonly destroyRef = inject(DestroyRef);

	private readonly userApiService = inject(UserApiService);

	private readonly userService = inject(UserService);

	private readonly navigationManagementService = inject(NavigationManagementService);

	private readonly dialogService = inject(DialogService);

	private readonly isEditingCurrentUser = this.dialogData.userId == null;

	private readonly userFormService = inject(UserFormService);

	/** Permission service. */
	protected readonly permissionService = inject(PermissionService);

	/** User data. */
	protected readonly userData$: Observable<User>;

	/** Whether form is loading. */
	protected readonly isLoading$ = new BehaviorSubject(false);

	/** User form. */
	protected readonly form = this.userFormService.initializeEditForm();

	public constructor() {
		super();
		this.userData$ = (this.dialogData.userId ?
			this.userApiService.getDetail(this.dialogData.userId) :
			this.userService.currentUser$
		).pipe(
			filterNull(),
			shareReplay({ refCount: true, bufferSize: 1 }),
		);
	}

	/** @inheritdoc */
	public ngOnInit(): void {
		merge(
			this.fillFormSideEffect(),
			this.disableFormSideEffect(),
		).pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe();
	}

	/** Handle form submission. */
	protected onSubmit(): void {
		this.permissionService.canEditUsers$.subscribe({
			next: canEditUser => {
				if (canEditUser) {
					this.editUser();
					return;
				}
				if (this.isEditingCurrentUser) {
					this.editProfilePicture();
				}
			},
		});
	}

	/** Edit permissions. */
	protected editPermissions(): void {
		this.dialogService
			.openDialogWith<PermissionsDialogComponent, PermissionDialogData, boolean>(PermissionsDialogComponent, {
			title: 'Advanced Settings',
			userId: this.dialogData.userId,
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: result => {
					if (result === true && this.dialogData.userId == null) {
						this.navigationManagementService.refresh$.next();
					}
				},
			});
	}

	private editProfilePicture(): void {
		const profilePicture = this.form.controls.profilePicture.getRawValue();
		if (profilePicture == null) {
			this.closeDialogWithResult(true);
			return;
		}
		this.userApiService.editProfilePicture(profilePicture).pipe(
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe({
				next: () => {
					this.userService.refresh();
					this.closeDialogWithResult(true);
				},
			});
	}

	private editUser(): void {
		this.form.markAllAsTouched();
		if (this.form.invalid) {
			return;
		}
		const formData = secureParse(this.form.getRawValue(), userEditSchema);
		this.userData$.pipe(
			switchMap(user => this.userApiService.edit(formData, user.id).pipe(
				toggleExecutionState(this.isLoading$),
			)),
			takeUntilDestroyed(this.destroyRef),
		)
			.subscribe({
				next: () => {
					this.userService.refresh();
					this.closeDialogWithResult(true);
				},
			});
	}

	private fillFormSideEffect(): Observable<void> {
		return this.userData$.pipe(
			first(),
			tap(user => {
				this.userFormService.fillEditFormData(this.form, user);
			}),
			ignoreElements(),
		);
	}

	private disableFormSideEffect(): Observable<void> {
		return this.permissionService.canEditUsers$.pipe(
			filter(canEdit => !canEdit),
			tap(() => {
				this.form.controls.fullName.disable();
				this.form.controls.email.disable();
				this.form.controls.locationId.disable();
				this.form.controls.roleId.disable();
			}),
			ignoreElements(),
		);
	}
}
