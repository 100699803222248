import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';

import { MenuComponent } from './components/menu/menu.component';
import { NavigationManagementService } from './navigation.service';

/** Menu. */
@Component({
	selector: 'meiw-navigation',
	templateUrl: './navigation.component.html',
	styleUrl: './navigation.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatTabsModule, MatButtonModule, RouterModule, CommonModule, MenuComponent],
})
export class NavigationComponent {
	private readonly navigationManagementService = inject(NavigationManagementService);

	/** Navigation links. */
	protected readonly navigation$ = this.navigationManagementService.navigation$;
}
