import { inject, Injectable } from '@angular/core';
import { NavigationService } from '@mei/common/core/services/navigation.service';
import { BehaviorSubject, shareReplay, switchMap } from 'rxjs';

/** Navigation service. */
@Injectable({
	providedIn: 'root',
})
export class NavigationManagementService {
	private readonly navigationService = inject(NavigationService);

	/** Refresh navigation links. */
	public readonly refresh$ = new BehaviorSubject<void>(undefined);

	/** Navigation links. */
	public readonly navigation$ = this.refresh$.pipe(
		switchMap(() => this.navigationService.getList()),
		shareReplay({ bufferSize: 1, refCount: true }),
	);
}
